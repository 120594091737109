<template>
  <b-tabs v-model="tabIndex">
    <!-- {{tabIndex}} -->
    <b-tab title="Blackouts" @click="clickTab">
      <ListBlackouts @set-tab-index="setTabIndex"/>
    </b-tab>
    <b-tab title="Crear" @click="clickTab" v-if="!contractIsCancelled">
      <BlackoutCreate @set-tab-index="setTabIndex"/>
    </b-tab>
    <b-tab v-if="!contractIsCancelled">
      <template #title >
        <div v-if="selectedBlackout">
          Actualizar
        </div>
      </template>
      <BlackoutEdit @set-tab-index="setTabIndex"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapState,mapMutations } from "vuex"
import ListBlackouts from '@/modules/fivesClub/components/catalogs/blackoutsContracts/ListBlackouts'
import BlackoutCreate from '@/modules/fivesClub/components/catalogs/blackoutsContracts/BlackoutCreate'
import BlackoutEdit from '@/modules/fivesClub/components/catalogs/blackoutsContracts/BlackoutEdit'

export default {
  components:{
    ListBlackouts,
    BlackoutCreate,
    BlackoutEdit
  },
 created() {

  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs',['selectedBlackout']),
    ...mapState('fivesClubContracts',['selectedContract']),
    contractIsCancelled(){
      return this.selectedContract?.statusContract == 3
    }
  },
  methods: {
    ...mapMutations('fivesClubCatalogs',['setSelectedBlackout']),
    setTabIndex( index ){
      this.tabIndex = index
    },
    clickTab(){
      this.setSelectedBlackout(null)
    }
  },
};
</script>
<style scoped>
.nav-item.active.tab-active-class {
  background-color: red;
}
</style>