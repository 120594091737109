<template>
    <ValidationObserver ref="createBlackout">
        <b-form @submit.prevent="save">
            <b-row class="pb-2">
                <b-col md="12" class="pb-1" >
                    <h6>Usar Blackout Existente</h6>
                </b-col>
                <b-col md="3">
                    <b-form-select
                        v-model="blackoutPreview" @input="bringBlackout(blackoutPreview)">
                        <option disabled value="">Seleccione una configuración existente</option>
                        <option v-for="botitle in blackoutsOptions" :key="botitle.id" :value="botitle.id">
                            {{ botitle.name }}
                        </option>
                    </b-form-select>
                </b-col>
                <b-col md="5">
                    <!-- <b-button variant="primary" v-b-tooltip.hover title="Aplicar Configuración">
                        <feather-icon icon="CheckIcon" size="16" />
                    </b-button> -->
                    <label v-if="isLoadingBlackouts">Cargando Fechas <b-spinner variant="primary" class="isCentered" label="Spinning" small/></label>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="3">
                    <ValidationProvider name="nombre" >
                        <b-form-group label="Nombre" slot-scope="{ errors }">
                            <b-form-input class="form-control" type="text" v-model="name"/>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="2">
                    <div class="float-left pt-1">
                        <b-form-checkbox v-model="status" :value="true" class="custom-control-success">
                            Activo
                        </b-form-checkbox>
                    </div>
                </b-col>

                <b-col md="2">
                    <div class="float-left pt-1">
                        <b-form-checkbox v-model="highseason" class="custom-control-success">
                            Temporada alta
                        </b-form-checkbox>
                    </div>
                </b-col>
                <b-col md="3" >
                    <b-button  variant="warning" class="btn-add" @click="resetBlackouts">
                        Reset
                    </b-button>
                </b-col>
            </b-row>

            <b-row v-for="(date, index) in rangeDates" :key="date.uuid">
                 <b-col md="3">
                    <ValidationProvider name="Fechas" >
                        <b-form-group :label="`${index+1}° fechas.`" slot-scope="{ valid, errors }">

                            <flat-pickr
                                :config="{ mode: 'range', minDate: 'today'}"
                                class="form-control"
                                v-model="date.range"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @input="setRange(date)"
                            />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="1" v-if="index > 0">
                    <b-button variant="danger" class="btn-borrar" @click="deleteInputDate(date.uuid)">
                        <feather-icon icon="TrashIcon" size="16" />
                    </b-button>
                </b-col>
                <b-col md="1" v-if="(index + 1) == rangeDates.length" v-b-tooltip.hover title="Añadir fecha">
                    <b-button variant="primary" class="btn-add" @click="addNewInputDate">
                        <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <div class="float-right">
                        <b-button type="submit" class="btn-block" variant="primary" :disabled="isSavingBlackout"
                        > <b-spinner small v-if="isSavingBlackout"/> Guardar
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </b-form>
    </ValidationObserver>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { showAlertMessage, stringAleatorio, toJson, sweetAlert } from '@/helpers/helpers'
import { createArrayDates, removeDatesDuplicated, checkExistingBlackouts, formatDateRangesBlackout } from '@/helpers/fivesClubHelper'
import flatPickr from 'vue-flatpickr-component'

export default {
    async mounted() {
        this.setCurrentConfig()
    },
    data() {
        return {
            name:'',
            dateStart: null,
            dateEnd: null,
            status: true,
            highseason:true,
            isSavingBlackout: false,
            rangeDates:[{
                range: '',
                dateStart: null,
                dateEnd: null,
                uuid: 'f1580ece-c4b2-11ec-9d64-0242ac120002'
            }],
            isLoadingBlackouts: false,
            blackoutPreview: '',
            selectedConfig: null,
            currentConfig: null,
        }
    },
    components: {
        flatPickr
    },
    computed: {
        ...mapState('auth',['user']),
        ...mapState("fivesClubContracts", ["selectedContract",]),
        ...mapState("fivesClubCatalogs", ["blackouts","blackoutsOptions"]),
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['saveBlackoutContracts','fetchBlackoutsContracts', 'fetchBlackoutsInfo']),
        ...mapMutations('fivesClubCatalogs', ['setBlackouts', 'setBlackoutsTitles']),
        setCurrentConfig(){
            const toClone = {
                name: this.name,
                status: this.status,
                highseason: this.highseason,
                rangeDates: this.rangeDates,
            }
            this.currentConfig = structuredClone(toClone)
        },
        async save(){
            this.isSavingBlackout = true
            if (!this.name || this.name == '' ) {
                this.isSavingBlackout = false
                return showAlertMessage( 'No se pudo guardar los cambios', 'InfoIcon', 'Coloque el nombre del blackout ante de continuar.', 'warning', 4000, 'bottom-right')
            }
            
            const invalidRangeDates = this.rangeDates.find(dates => dates.range == '' || dates.dateStart == null || dates.dateEnd == null)
            if (invalidRangeDates ) {
                this.isSavingBlackout = false
                return showAlertMessage( 'No se pudo guardar los cambios', 'InfoIcon', 'Hay campos sin fecha, por favor llénelos antes de continuar.', 'warning', 4000, 'bottom-right')
            }
            const { newDates, thereAreDuplicates } = removeDatesDuplicated( this.rangeDates )
            if (thereAreDuplicates) showAlertMessage( 'Ok', 'InfoIcon', 'Se han removido fechas duplicadas', 'danger', 4000, 'bottom-right')
            this.rangeDates = newDates //remuevo duplicados
            const {exists, msg} = checkExistingBlackouts({
                actualBl: this.blackouts,
                newBl: {
                    newDates: newDates,
                    name: this.name
                },
            })
            if (exists) {
                this.isSavingBlackout = false
                return showAlertMessage( 'No se pudo guardar los cambios', 'InfoIcon', msg, 'danger', 4000, 'bottom-right')
            }
            const payload = {
                idUser: this.user.idUser,
                idContract:this.selectedContract.id,
                name: this.name,
                dates: createArrayDates(this.rangeDates),
                highseason:Boolean(this.highseason),
                status: Boolean(this.status),
            }
            const { status, message } = await this.saveBlackoutContracts( payload ) // saving in backend

            if(status){
                showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right')
                this.isSavingBlackout = false
                Object.assign( this.$data, this.$options.data() )
                this.$refs.createBlackout.reset() //reset form
                //vuelvo a pedir la info del back
                const idContract = this.selectedContract.id
                const blackouts  = await this.fetchBlackoutsContracts(idContract)
                this.setBlackouts(blackouts)
                this.$emit('set-tab-index', 0)
            }

            this.isSavingBlackout = false
        },
        addNewInputDate(){
            const uuid = stringAleatorio()
            this.rangeDates.push({range: '', dateStart: null, dateEnd: null,  uuid: uuid})
            return uuid
        },
        deleteInputDate(uuid){
            this.rangeDates = this.rangeDates.filter( date => date.uuid !== uuid)
        },
        setRange( date ){
            const { dateStart, dateEnd, range, uuid } = date
            const idx = toJson( this.rangeDates.findIndex( date => date.uuid === uuid))
            if( range ) {
                const splitdate = range.split(' to ')
                this.rangeDates[idx].range = range
                if (splitdate[1]){
                    this.rangeDates[idx].dateStart = splitdate[0]
                    this.rangeDates[idx].dateEnd = splitdate[1]
                } else {
                    this.rangeDates[idx].dateStart = splitdate[0]
                    this.rangeDates[idx].dateEnd = splitdate[0]
                }
            }
            else if (dateStart && dateEnd) {
                this.rangeDates[idx].dateStart = dateStart
                this.rangeDates[idx].dateEnd = dateEnd
                this.rangeDates[idx].range = `${dateStart} to ${dateEnd}`
            } else {
                this.rangeDates[idx].dateStart = null
                this.rangeDates[idx].dateEnd = null
                this.rangeDates[idx].range = ''
            }
        },
        async detectChanges(data) {
            const nameHasChanged = this.currentConfig?.name != this.selectedConfig?.name
            const statusHasChanged = this.currentConfig?.status != this.selectedConfig?.status
            const highseasonHasChanged = this.currentConfig?.highseason != this.selectedConfig?.highseason

            const currentRange = formatDateRangesBlackout(this.currentConfig?.rangeDates)
            const selectedRange = formatDateRangesBlackout(this.selectedConfig?.rangeDates)

            const rangeDatesHasChanged = (JSON.stringify(currentRange) != JSON.stringify(selectedRange))

            const hasChanged = nameHasChanged || statusHasChanged || highseasonHasChanged || rangeDatesHasChanged

            const newBlackouts = this.blackoutsOptions?.find(bo => bo.id == data)
            const actualBlackouts = this.selectedConfig ? `de ${this.selectedConfig?.name}` : 'su configuración actual'

            let confirmations = {
                changesConfirmed: false,
                noButProceed: false,
            }

            if (this.selectedConfig && hasChanged) {
                const swalert = {
                    title: `Cambiando a Blackout ${newBlackouts?.name}`,
                    message: `Se han detectado cambios, estos se perderán al cambiar de configuración.\n ¿Desea cambiar ${actualBlackouts} por ${newBlackouts?.name} de todas formas?`,
                    icon: 'warning',
                    confirmButton: 'Confirmar',
                    cancelButton: 'Cancelar',
                }

                const {isConfirmed} = await sweetAlert(swalert)
                if (!isConfirmed) showAlertMessage( 'Se ha cancelado la operación', 'InfoIcon', 'No se ha actualizado la configuración actual.', 'warning', 4000, 'bottom-right')
                confirmations.changesConfirmed = isConfirmed

            } else confirmations.noButProceed = true
            return confirmations
        },
        async bringBlackout(data){
            this.isLoadingBlackouts = true
            this.setCurrentConfig()
            const {changesConfirmed, noButProceed} = await this.detectChanges(data)

            if (changesConfirmed || noButProceed) {
                const payload = {
                    typeReq: 'data',
                    idBlackout: data
                }
                const response = await this.fetchBlackoutsInfo(payload)
                if (response) {
                    this.resetBlackouts()
                    const {name, status, highseason, datesBlackout} = response [0]

                    let uuid
                    let counter = 1
                    datesBlackout.forEach(dates => {
                        const firstUuid = 'f1580ece-c4b2-11ec-9d64-0242ac120002'
                        const today = new Date()
                        const dateStart = new Date(dates.dateStart)
                        const valid = dateStart > today

                        if (valid) {
                            if (counter == 1) uuid = firstUuid
                            if (counter != 1) uuid = this.addNewInputDate()
                            this.setRange({...dates, uuid: uuid})

                            counter++
                        }
                    })

                    this.name = name
                    this.status = status
                    this.highseason = highseason

                    this.setCurrentConfig()
                    this.selectedConfig = structuredClone(this.currentConfig)
                } else null
            } else null
            // this.blackoutPreview = this.blackoutsOptions?.find(bo => bo.name == this.name).id

            this.isLoadingBlackouts = false
        },
        resetBlackouts(){
            this.name = '',
            this.status =  true,
            this.highseason = true,
            this.rangeDates = [{
                range: '',
                dateStart: null,
                dateEnd: null,
                uuid: 'f1580ece-c4b2-11ec-9d64-0242ac120002'
            }],
            this.selectedConfig = null,

            this.setCurrentConfig()
        },

    },
};
</script>
<style scoped>
.custom-control {
    margin-block-start: 1.5rem;
}
.btn-add{
    margin-block-start: 1.9rem;
}
.btn-borrar{
    margin-block-start: 1.9rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>