<template>
  <b-modal size="lg" :id="'modal-contracts' + contract.id" :title="'Contrato: ' + contract.code" ok-only hide-footer >
    <h4>Tipos de pago</h4>
    <b-row class="mt-1 p-1">
      <b-table
        responsive
        :items="typepayments"
        :fields="tableColumns"
        show-empty
        empty-text="No se encontraron registros"
        class="position-relative mb-0"
        primary-key="id"
        sticky-header
        fixed
        small
        style="overflow: auto"
      >
        <template #cell(paymenttypename)="row">
          <b-form-select
            v-model="row.item.paymentType"
            @change="isUpdating = true"
          >
            <option v-for="pt in paymentTypes" :key="pt.id" :value="pt.id">
              {{ pt.name }}
            </option>
          </b-form-select>
        </template>
        <template #cell(returntypename)="row">
          <b-form-select
            v-model="row.item.returnType"
            @change="isUpdating = true"
          >
            <option v-for="ret in retornos" :key="ret.id" :value="ret.id">
              {{ ret.name }}
            </option>
          </b-form-select>
        </template>
      </b-table>
      <hr />
      <b-col md="3">
        <b-button
          v-if="isUpdating"
          variant="primary"
          @click="updatePayments(contract)"
        >
          Actualizar
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";

export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
    typepayments: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'dateInit', label: 'Fecha de Inicio' },
        { key: 'dateEnd', label: 'Fecha de Término' },
        { key: 'paymenttypename', label: 'Tipo de pago' },
        { key: 'returntypename', label: 'Tipo de retorno' },
      ],
      isUpdating: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('fivesClubCatalogs', ['paymentTypes', 'retornos']),
    ...mapState('fivesClubContracts', ['contractstatus','contracts','requestContracts']),
    showStatusContract() {
      return this.contractstatus
    },
  },
  methods: {
    ...mapActions('fivesClubContracts', ['updateContractStatusFromTable', 'fetchContracts']),
    ...mapMutations('fivesClubContracts', ['setContracts','setIsLoadingContracts']),
    async updatePayments(data) {
      let array = []
      let info
      const isConfirmed = await this.confirmSaving()
      if (isConfirmed) {
        try {
          array.push({
            id: data.id,
            paymentType: data.paymentType,
            returnType: data.returnType,
          })
          info = { PaymentTypes: array}
          const response = await this.updateContractStatusFromTable(info);
          if (response) {
            showAlertMessage('Cambio realizado', 'InfoIcon', 'Se ha completado la actualización', 'success', 4000, 'bottom-right')
            this.$root.$emit('bv::hide::modal', 'modal-contracts' + this.contract.id )
          }
        } catch (error) {
          showAlertMessage( 'Cambio cancelado', 'InfoIcon', 'Se ha producido un error en la actualización', 'danger', 4000, 'bottom-right' )
        }
      }
    },
    async confirmSaving() {
      const { isConfirmed } = await this.$swal.fire({
        title: "Cambiando tipos de pago del contrato",
        text: `¿Desea actualizar el contrato`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        
      })
      return isConfirmed
    },
  },
};
</script>
