<template>
  <b-card v-if="selectedContract">
    <div class="d-flex justify-content-between">
      <h3>{{selectedContract.resortsname}} | {{selectedContract.housingnumber}} | {{selectedContract.membershipcode}}</h3><br>
      <b-dropdown variant="link" toggle-class="p-0" no-caret right>
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="24" class="align-middle text-body" />
        </template>

        <b-dropdown-item @click="goToHome">
          <feather-icon icon="ArrowLeftIcon" />
          <span class="align-middle ml-50">Volver a contratos</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{name: 'contractsInfo', params: { contracts: 'contracts-' + selectedContract.id }}">
          <feather-icon icon="DollarSignIcon" />
          <span class="align-middle ml-50">Ver Saldo de beneficios</span>
        </b-dropdown-item>
        <b-dropdown-item @click="showModalOwners(selectedContract)">
          <feather-icon icon="UsersIcon" />
          <span class="align-middle ml-50">Ver propietarios</span>
          <ModalOwners :contract="selectedContract" :owners="owners" />
        </b-dropdown-item>
        <b-dropdown-item
          @click="showModalCliente(selectedContract.id)"
          v-can="'fivesclub_contracts_show_contracts_action_button_quick_edit'"
        >
          <feather-icon icon="ListIcon" />
          <span class="align-middle ml-50">Tipos de Pago</span>
          <ModalContracts :contract="selectedContract" :typepayments="tableData" />
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-overlay :show="isUpdatingContract" rounded class="p-2">
      <ValidationObserver ref="contractEdit" v-slot="{ invalid }">
        <b-form @submit.prevent="updateContractOwner">
          <b-row class="border rounded p-1">
            <b-col md="12">
              <div class="d-flex justify-content-between">
                <h4>Datos generales</h4>
              </div>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="" name="Resort">
                <b-form-group label="Resort" slot-scope="{ valid, errors }">
                  <b-form-input
                    class="form-control input-disabled"
                    v-model="selectedContract.resortsname"
                    :state="errors[0] ? false : valid ? true : null"
                    disabled
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="" name="Vivienda">
                <b-form-group label="Vivienda" slot-scope="{ valid, errors }">
                  <b-form-input
                    type="number"
                    class="form-control input-disabled"
                    v-model="selectedContract.housingnumber"
                    :state="errors[0] ? false : valid ? true : null"
                    disabled
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Propietario">
                <b-form-group
                  label="Propietario"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedContract.owner"
                    :disabled="contractIsCancelledOrSigned"
                    :class="contractIsCancelled || contractIsSigned ? 'input-disabled' : ''"
                  >
                    <option selected value="">Elija Propietario</option>
                    <option
                      v-for="owner in selectedContract.owners"
                      :key="owner.currentidOwner"
                      :value="owner.currentidOwner"
                      :selected="owner.currentidOwner === selectedContract.owner"
                    >
                      {{ owner.ownerName }} {{ owner.ownerLastname }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Tipo Membresía">
                <b-form-group
                  label="Tipo Membresía"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedContract.membershipid"
                    @change="getBenefits(update.selectedmembership)"
                    disabled
                    class="input-disabled"
                  >
                    <option selected value="">Tipo de Membresía</option>
                    <option
                      v-for="member in memberships"
                      :key="member.id"
                      :value="member.id"
                    >
                      {{ member.code }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider name="Estatus Contrato" rules="required">
                <b-form-group
                  label="Estatus Contrato"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    :disabled="contractIsCancelled"
                    v-model="statusSelected"
                    @change="statusId(statusSelected)"
                    :class="contractIsCancelled ? 'input-disabled' : ''"
                  >
                    <option :value="null" disabled>Seleccione Estatus Contrato</option>
                    <option
                      v-for="status in showStatusContract"
                      :key="status.id"
                      :value="status.id"
                    >
                      {{ status.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Tipo de pago">
                <b-form-group
                  label="Tipo de pago"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedContract.paymentType"
                    :disabled="isEditable"
                    :class=" contractIsCancelled || contractIsSigned ? 'input-disabled' : ''"
                  >
                    <option value="">Tipo de pago</option>
                    <option
                      v-for="pt in paymentTypes"
                      :key="pt.id"
                      :value="pt.id"
                    >
                      {{ pt.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Tipo de retorno">
                <b-form-group
                  label="Tipo de retorno"
                  slot-scope="{ valid, errors }"
                >
                  <b-form-select
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="selectedContract.returnType"
                    :disabled="isEditable"
                    :class="contractIsCancelled || contractIsSigned ? 'input-disabled' : '' "
                  >
                    <option value="">Tipo de retorno</option>
                    <option
                      v-for="ret in retornos"
                      :key="ret.id"
                      :value="ret.id"
                    >
                      {{ ret.name }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Duracion">
                <b-form-group label="Duracion" slot-scope="{ valid, errors }">
                  <b-form-input
                    class="form-control"
                    type="number"
                    v-model="selectedContract.duration"
                    :state="errors[0] ? false : valid ? true : null"
                    @keypress="onlyNumber"
                    disabled
                    :class="contractIsCancelled || contractIsSigned ? 'input-disabled' : ''"
                    @input="addDatesDuration(selectedContract.duration)"
                  >
                  </b-form-input>
                  <b-form-invalid-feedback>{{
                    errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Inicio">
                <b-form-group label="Inicio" slot-scope="{ valid, errors }">
                  <b-form-input
                    class="form-control"
                    type="date"
                    v-model="selectedContract.dateStart"
                    :state="errors[0] ? false : valid ? true : null"
                    @input="addEndDateDuration(selectedContract.dateStart)"
                    disabled
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="required" name="Fin">
                <b-form-group label="Fin" slot-scope="{ valid, errors }">
                  <b-form-input
                    class="form-control"
                    type="date"
                    v-model="selectedContract.dateEnd"
                    disabled
                    :state="errors[0] ? false : valid ? true : null"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3" v-if="contractIsCancelled || isCancel">
              <ValidationProvider rules="required" name="Término del Contrato">
                <b-form-group label="Término del Contrato" slot-scope="{ valid, errors }">
                  <b-form-input
                    class="form-control"
                    type="date"
                    v-model="selectedContract.endDate"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                    <!-- :disabled="contractIsCancelled && selectedContract.endDate" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3" >
              <b-row>
                <b-col>
                  <ValidationProvider rules="" name="temporal">
                    <b-form-group>
                      <label>Es temporal*</label>
                      <b-form-checkbox
                        v-model="selectedContract.isTemporal"
                        switch
                        @change="setIsTemporalSelectedContract(selectedContract.isTemporal)"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col>
                  <ValidationProvider rules="" name="en venta">
                    <b-form-group>
                      <label>Propiedad en Venta</label>
                      <b-form-checkbox
                        v-model="selectedContract.isResale"
                        switch
                        :disabled="editableResalePrice"
                        @change="setOnSaleSelectedContract(selectedContract.isResale)"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="3" v-if="selectedContract.isResale">
              <ValidationProvider :rules="selectedContract.isResale ? 'required' : '' " name="Valor de reventa">
                <b-form-group label="Valor de Reventa" slot-scope="{ valid, errors }">
                  <b-input-group prepend="$">
                    <b-form-input
                      class="form-control"
                      type="text"
                      @keypress="onlyNumberWithDecimal"
                      v-model="selectedContract.priceResale"
                      :disabled="editableResalePrice"
                      :state="errors[0] ? false : valid ? true : null"
                    />
                      <!-- :disabled="contractIsCancelled && selectedContract.endDate" -->
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="6">
              <ValidationProvider rules="" name="Notas">
                <b-form-group label="Notas" slot-scope="{ valid, errors }">
                  <b-form-textarea
                    class="form-control"
                    v-model="selectedContract.notes"
                    :state="errors[0] ? false : valid ? true : null"
                    :disabled="contractIsCancelled"
                    :class="contractIsCancelled || contractIsSigned ? 'input-disabled' : '' "
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>


            <b-col md="12" v-if="contractIsCancelled || isCancel">
              <ValidationProvider rules="" name="Notas de cancelacion">
                <b-form-group label="Notas de Cancelación " slot-scope="{ valid, errors }">
                  <b-form-textarea
                    class="form-control"
                    v-model="selectedContract.cancellationNotes"
                    :state="errors[0] ? false : valid ? true : null"
                    :disabled="contractIsCancelled"
                    :class="contractIsCancelled || contractIsSigned ? 'input-disabled' : '' "
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
              <b-alert variant="danger" v-if=" !contractIsCancelled" show>
                <div class="alert-body text-center">
                  <span><strong>Favor de poner notas de cancelación </strong></span>
                </div>
              </b-alert>
            </b-col>

            <b-col md="12">
              <b-alert variant="danger" v-if="contractIsSigned" show>
                <div class="alert-body text-center">
                  <span><strong>Contrato Firmado</strong>: A este contrato solo se le puede modificar la nota.</span>
                </div>
              </b-alert>

              <b-alert variant="danger" v-if="contractIsCancelled" show>
                <div class="alert-body text-center">
                  <span>
                    <strong>Contrato Cancelado</strong>
                  </span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <b-row class="mt-1 border-black-membership rounded mb-1">
            <b-col md="12">
              <app-collapse v-if="isLoadingBlackouts">
                <app-collapse-item title="Blackouts"       >
                  <b-card>
                    <div class="isCentered isSpinner" v-if="isLoadingBlackouts == false" >
                      <center>
                        <b-spinner class="isCentered" label="Spinning" ></b-spinner>
                        <br /> Cargando Blackouts
                      </center>
                    </div>
                    <Blackouts v-if="isLoadingBlackouts == true" />
                  </b-card>
                </app-collapse-item>
              </app-collapse>
              <div class="text-center mt-2" v-if="!isLoadingBlackouts">
                <b-spinner style="width: 3rem; height: 3rem" /><br />
                <strong>Cargando Blackouts</strong>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 border-benfits-membership rounded mb-1">
            <b-col md="12">
              <app-collapse v-if="!isGettingBenefits">
                <app-collapse-item title="Beneficios de la membresía del contrato">
                  <b-input
                    v-if="selectedContract.benefitsMembership.length"
                    v-model="filterBenefits"
                    placeholder="Busqueda por nombre en la tabla"
                    class="mb-2"
                  />
                  <div class="table-responsive tbodyDiv" v-if="selectedContract.benefitsMembership.length">
                    <table class="table">
                      <thead class="sticky-top">
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Inicio</th>
                          <th scope="col">Fin</th>
                          <th scope="col">Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="benefit in filteredBenefitsMembership" :key="benefit.idBenfit">
                          <th scope="row">{{ benefit.benefitname }}</th>
                          <td>
                            <b-form-input
                              class="form-control  input-size-sm"
                              type="date"
                              disabled
                              v-model="benefit.dateIn"
                              :min="selectedContract.dateStart"
                              :max="selectedContract.dateEnd"
                            ></b-form-input>
                          </td>
                          <td>
                            <b-form-input
                              class="form-control input-size-sm"
                              type="date"
                              disabled
                              v-model="benefit.dateOut"
                              :min="selectedContract.dateStart"
                              :max="selectedContract.dateEnd"
                            />
                          </td>
                          <td>
                            <b-form-input
                              class="form-control input-size-sm"
                              type="number"
                              step="any"
                              :disabled="contractIsProp(benefit)"
                              v-model="benefit.qty"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <b-alert show variant="danger">
                      <div class="alert-body">
                        <feather-icon class="mr-25" icon="InfoIcon" />
                        <span class="ml-25">
                          Esta membresía no tiene beneficios.
                        </span>
                      </div>
                    </b-alert>
                  </div>
                </app-collapse-item>
              </app-collapse>
              <div class="text-center mt-2" v-if="isGettingBenefits">
                <b-spinner style="width: 3rem; height: 3rem" /><br />
                <strong>Cargando beneficios de la membresía del contrato</strong>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1 border-extra-benfits-membership rounded mb-1">
            <b-col md="12">
              <app-collapse v-if="!isGettingBenefits">
                <app-collapse-item title="Beneficios extra" >
                  <b-input
                    v-if="selectedContract.benefitsExtra.length"
                    v-model="filterExtraBenefits"
                    placeholder="Busqueda por nombre en la tabla"
                    class="mb-2"
                  ></b-input>
                  <div v-if="!isGettingBenefits">
                    <div
                      class="table-responsive tbodyDiv"
                      v-if="selectedContract.benefitsExtra.length"
                    >
                      <table class="table table-responsive">
                        <thead class="sticky-top">
                          <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Inicio</th>
                            <th scope="col">Fin</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Notas</th>
                            <!-- <th scope="col">Acciones</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              benefit, index
                            ) in filteredExtraBenefitsMembership"
                            :key="index"
                            :class="
                              benefit.isReseted
                                ? 'Reseted'
                                : benefit.statusBenefits == 1
                                ? ''
                                : 'Cancelado'
                            "
                          >
                            <th scope="row">{{ benefit.benefitname }}</th>
                            <td>{{ benefit.dateIn }}</td>
                            <td>{{ benefit.dateOut }}</td>
                            <td>
                              {{
                                benefit.statusBenefits == 1
                                  ? "Activo"
                                  : "Cancelado"
                              }}
                            </td>
                            <td>
                              <b-form-input
                                class="form-control input-size-sm"
                                type="number"
                                v-model="benefit.qty"
                                step="any"
                                @input="qtyExtrabenefit(benefit)"
                                :disabled="true"
                              />
                              <!-- contractIsCancelled ||  benefit.statusBenefits != 1 -->
                            </td>
                            <td>
                              <textarea cols="40" rows="2" :disabled="true" v-model="benefit.notes"></textarea>
                              <!-- <b-form-input
                                class="form-control input-size-sm"
                                type="text"
                                v-model="benefit.notes"
                                :disabled="true"
                              /> -->
                              <!-- contractIsCancelled ||  benefit.statusBenefits != 1 -->
                            </td>
                            <!-- <td>
                              <b-row>
                                <b-col>
                                  <b-form-group v-can="'fivesclub_contracts_show_contracts_action_button_edit_contract_delete_bennefit'">
                                    <b-button
                                    benefit.statusBenefits = 2
                                      variant="danger"
                                      size="sm"
                                      @click="deleteBenefitExtra(benefit)"
                                      :disabled="contractIsCancelled"
                                    >
                                      <feather-icon
                                        size="16"
                                        icon="Trash2Icon"
                                        class="mr-40"
                                      />
                                    </b-button>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <b-alert show variant="danger" v-else>
                      <div class="alert-body">
                        <feather-icon class="mr-25" icon="InfoIcon" />
                        <span class="ml-25"
                          >No hay beneficios extra para agregar.</span
                        >
                      </div>
                    </b-alert>
                  </div>
                </app-collapse-item>
              </app-collapse>
              <div class="text-center mt-2" v-if="isGettingBenefits">
                <b-spinner style="width: 3rem; height: 3rem" /><br />
                <strong>Cargando beneficios extra del contrato</strong>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1 border-new-benfits-membership rounded mb-1">
            <b-col md="12">
              <app-collapse v-if="!isGettingBenefits">
                <app-collapse-item title="Nuevos beneficios" >
                  <b-input
                    v-if="selectedContract.benefitsNew.length"
                    v-model="filterNewBenefits"
                    placeholder="Busqueda por nombre en la tabla"
                    class="mb-2"
                  ></b-input>
                  <div v-if="!isGettingBenefits">
                    <div
                      class="table-responsive tbodyDiv"
                      v-if="selectedContract.benefitsNew.length"
                    >
                      <table class="table">
                        <thead class="sticky-top">
                          <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Inicio</th>
                            <th scope="col">Fin</th>
                            <th scope="col">Cantidad</th>
                            <!-- <th scope="col">Monto</th> -->
                            <th scope="col">Notas Internas</th>
                            <th scope="col">Traspaso de Saldo</th>
                            <th scope="col">Añadir</th>
                            <th scope="col">Reset</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              benefit, index
                            ) in filteredNewBenefitsMembership"
                            :key="index"
                            :class="benefit.isSelected ? 'table-success' : ''"
                          >
                            <th scope="row">{{ benefit.benefitname }}</th>
                            <td>
                              <b-form-group>
                                <b-form-input
                                  class="form-control"
                                  type="date"
                                  v-model="benefit.dateIn"
                                  :min="fechaActual"
                                  :max="selectedContract.dateEnd"
                                  :disabled="contractIsCancelled"
                                  @input="setPartialDataNewBenefit(benefit)"
                                ></b-form-input>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group>
                                <b-form-input
                                  class="form-control"
                                  type="date"
                                  v-model="benefit.dateOut"
                                  :min="fechaActual"
                                  :max="selectedContract.dateEnd"
                                  :disabled="contractIsCancelled"
                                  @input="setPartialDataNewBenefit(benefit)"
                                ></b-form-input>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-input
                                class="form-control input-size-sm"
                                type="number"
                                step="any"
                                v-model="benefit.qty"
                                :disabled="contractIsCancelled"
                                @input="setPartialDataNewBenefit(benefit)"
                              ></b-form-input>
                            </td>

                            <td>
                              <b-form-input
                                class="form-control input-size-sm"
                                type="text"
                                v-model="benefit.notes"
                                @input="setPartialDataNewBenefit(benefit)"
                              ></b-form-input>
                            </td>
														<td>
                              <b-form-group>
                                <b-form-checkbox
                                  v-model="benefit.isNew"
                                  switch
                                />
                              </b-form-group>
														</td>
                            <td>
                              <b-button
                                variant="primary"
                                size="sm"
                                @click="completeDataNewBenefit(benefit)"
                                :disabled="contractIsCancelled"
                                v-can="
                                  'fivesclub_contracts_show_contracts_action_button_edit_add_benefits'
                                "
                              >
                                <feather-icon
                                  size="16"
                                  :icon="
                                    benefit.isSelected
                                      ? 'CheckCircleIcon'
                                      : 'PlusIcon'
                                  "
                                  class="mr-40"
                                />
                              </b-button>
                            </td>
                            <td>
                              <b-button
                                variant="warning"
                                size="sm"
                                @click="clearNewBenefit(benefit)"
                                :disabled="contractIsCancelled"
                              >
                                <feather-icon
                                  size="16"
                                  icon="RefreshCcwIcon"
                                  class="mr-40"
                                />
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <b-alert show variant="danger" v-else>
                      <div class="alert-body">
                        <feather-icon class="mr-25" icon="InfoIcon" />
                        <span class="ml-25"
                          >Esta membresía no tiene beneficios.</span
                        >
                      </div>
                    </b-alert>
                  </div>
                </app-collapse-item>
              </app-collapse>
              <div class="text-center mt-2" v-if="isGettingBenefits">
                <b-spinner style="width: 3rem; height: 3rem" /><br />
                <strong
                  >Cargando nuevos beneficios para añadir al contrato</strong
                >
              </div>
            </b-col>
          </b-row>
          <!-- Actualizar no es posible solo sí el contrato está cancelado (O, recientemente, si tiene el permiso) -->
          <b-row v-if="!contractIsCancelled || can('fivesclub_contracts_show_contracts_action_button_edit_update_even_canceled')">
            <b-col>
              <div class="float-right">
                <b-button
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="(invalid || isUpdatingContract || isCancellingContract)"
                  v-can="'fivesclub_contracts_show_contracts_action_button_edit_update'"
                >
                  <b-spinner small v-if="isUpdatingContract" /> Actualizar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
  <b-card v-else>
    <b-col md="12">
      <div class="d-flex justify-content-between">
        <div><h3>Beneficios y condiciones</h3></div>
        <div>
          <b-button size="sm" variant="warning" @click="goToHome">
            <b-icon-arrow-left/> Regresar
          </b-button>
        </div>
      </div>
    </b-col>
    <b-card-body>
      <b-alert variant="danger" show>
        <div class="alert-body">
          Debe de seleccionar un contrado desde el listado de contratos
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>
<script>

import * as moment from "moment"
import { mapState, mapActions, mapMutations } from "vuex"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import {
  currentDate,
  stringAleatorio,
  showAlertMessage,
  sweetAlert,
} from "@/helpers/helpers"
import { makeParamsFilterContracts } from "@/helpers/fivesClubHelper"
import ModalOwners from "@/modules/fivesClub/components/contracts/ModalOwners"
import ModalContracts from "@/modules/fivesClub/components/contracts/ModalContracts"
import Blackouts from "@/modules/fivesClub/components/catalogs/blackoutsContracts/Blackouts"

export default {
	mixins: [utils, acl],
	components: {
		AppCollapse,
		AppCollapseItem,
		ModalOwners,
		Blackouts,
		ModalContracts,
	},
	async created() {
		this.isGettingBenefits = true
		await this.getInitialData()
		if (this.selectedContract) {
			const { id } = this.selectedContract
			await this.getDataForSelectedContract(id)
		}
    this.statusSelected = structuredClone(this.selectedContract.statusContract)
		this.isGettingBenefits = false
  },
	data() {
		return {
			isLoadingBlackouts: false,
			filterBenefits: "",
			filterNewBenefits: "",
			filterExtraBenefits: "",
			extrafilter: "",
			name: "",
			typeMembership: null,
			membershipBenefits: [],
			status: true,
			isCancel: false,
			//loader
			isGettingBenefits: false,
			isUpdatingContract: false,
			visible: true,
			fechaActual: currentDate(),
			owners: [],
			tableData: [{
        id: "",
        dateInit: "",
        dateEnd: "",
        paymentType: "",
        paymenttypename: "",
        returnType: "",
        returntypename: "",
      }],
      statusSelected: null
		}
  },
  computed: {
    ...mapState("fivesClubContracts", [ "selectedContract", "contractstatus", "contracts", "requestContracts", ]),
    ...mapState("fivesClubCatalogs", [ "typeMemberships", "paymentTypes", "retornos", "benefits", "memberships", ]),
    ...mapState("start", ["hotels", "currencies"]),
    ...mapState("auth", ["user"]),
    contractIsCancelledOrSigned(){
		  const statusValid = ["CANCELADO", "FIRMADO"]
      return statusValid.includes(this.selectedContract.statuscontractname)
    },
    isCancellingContract() {
      if (this.statusSelected == 3) return this.selectedContract.endDate == null || this.selectedContract.endDate == ''
      else return false
    },
    contractIsCancelled() {
      const statusValid = ["CANCELADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      this.isCancel =statusValid.includes(this.selectedContract.statuscontractname)
      return statusValid.includes(this.selectedContract.statuscontractname)
    },
    contractIsSigned() {
      const statusValid = ["FIRMADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      return statusValid.includes(this.selectedContract.statuscontractname)
    },
    isEditable(){
      let isDisabled = false
      const statusNoEditable = ["CANCELADO","FIRMADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      const statusEditable = ["PROPUESTA"]
      const cancelledOrSigned = statusNoEditable.includes(this.selectedContract.statuscontractname)
      const esPropuesta = statusEditable.includes(this.selectedContract.statuscontractname)
      if(cancelledOrSigned) isDisabled = true
      if(esPropuesta) isDisabled = false
      return isDisabled
    },
    showStatusContract() {
      if (!this.contractstatus) return []
      if (this.selectedContract.statusContract === 1){
        return this.contractstatus.filter((stat) => stat.id == 1 || stat.id == 2  || stat.id == 3)
      }
      if (this.selectedContract.statusContract === 2){
        return this.contractstatus.filter((stat) => stat.id == 2 || stat.id == 3 )
      }
      if (this.selectedContract.statusContract === 3) {
        return this.contractstatus.filter((stat) => stat.id == 3 )
      }
      return this.contractstatus
    },
    editableResalePrice(){
      const statusValid = ["PROPUESTA","FIRMADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      const valid = statusValid.includes(this.selectedContract.statuscontractname)
      return !valid
    },
    filteredBenefitsMembership() {
      return this.selectedContract.benefitsMembership.filter((row) => {
        const nameBenefit = row.benefitname.toString().toLowerCase()
        const searchTerm = this.filterBenefits.toLowerCase()
        return nameBenefit.includes(searchTerm)
      })
    },
    filteredNewBenefitsMembership() {
      return this.selectedContract.benefitsNew.filter((row) => {
        const nameBenefit = row.benefitname.toString().toLowerCase()
        const searchTerm = this.filterNewBenefits.toLowerCase()
        return nameBenefit.includes(searchTerm)
      })
    },
    filteredExtraBenefitsMembership() {
      return this.selectedContract.benefitsExtra.filter((row) => {
        const nameBenefit = row.benefitname.toString().toLowerCase()
        const searchTerm = this.filterExtraBenefits.toLowerCase()
        return nameBenefit.includes(searchTerm)
      })
    },
  },
  methods: {
    ...mapActions("fivesClubContracts", [ "fetchContractBenefits", "getInitialContentContracts", "updateContract", "fetchContracts", "fetchInfoOwnerOrHousing", "fetchContractPaymentTypes", "deleteBenefitInContract", "contractActiveServices"]),
    ...mapActions("fivesClubCatalogs", ["fetchBlackoutsContracts", "fetchBlackoutsInfo"]),
    ...mapMutations("fivesClubContracts", ["setSelectedContract","setBenefitsInSelectedContract", "setExtraBenefitsInSelectedContract", "setNewBenefitsInSelectedContract", "completeDataNewBenefitInSelectedContract", "resetDataNewBenefitInSelectedContract", "resetExtraBenefitInSelectedContract", "setDataExtraBenefitInSelectedContract", "setContracts", "setIsLoadingContracts", "setDatesNewBenefitsEditContract", "resetAllDataNewBenefitInSelectedContract", "setDataDurationEditContract","setDatesEditContract", "setIsTemporalSelectedContract","setOnSaleSelectedContract"]),
    ...mapMutations("fivesClubCatalogs", ["setMemberships", "setRetornos","setBlackouts"]),

    async getInitialData() {
      if (
        !this.retornos.length ||
        !this.paymentTypes.length ||
        !this.memberships.length
      ) await this.getInitialContentContracts()

      this.isLoadingBlackouts = false
      const idContract = this.selectedContract.id
      await this.fetchBlackoutsInfo({typeReq: 'title'})
      const blackouts = await this.fetchBlackoutsContracts(idContract)
      this.setBlackouts(blackouts)

  		this.isLoadingBlackouts = true
    },
    addDatesDuration(duration){
      const dateStart = currentDate()
      if(duration != ''){
        const dateEnd = moment(dateStart, "YYYY-MM-DD").add(duration, 'years').format('YYYY-MM-DD') //le añado fecha final en base a la fecha de inicial
        this.setDataDurationEditContract({duration, dateStart, dateEnd })
      }
    },
    addEndDateDuration(dateStart){
      const { duration } = this.selectedContract
      if(duration != ''){
        const dateEnd = moment(dateStart, "YYYY-MM-DD").add(duration, 'years').format('YYYY-MM-DD') //le añado el fecha final en base a la fecha de inicial
        this.setDatesEditContract({dateStart, dateEnd})
      }
    },
    contractIsProp(benefit) {

      let year=  moment(benefit.dateIn).format("YYYY")
      let yearEnd=  moment(benefit.dateOut).format("YYYY")
      let yearC=  moment(this.selectedContract.dateStart).format("YYYY")
      let yearEndC=  moment(this.selectedContract.dateEnd).format("YYYY")

      let isDisabled = false
      const esPropuesta = this.selectedContract.statusContract == 1// propuesta -> 1, firmado-> 2, cancelado ->  3, pendiente -> 4,

      if(esPropuesta && (year==yearC || yearEnd==yearEndC) ) isDisabled = false
      else isDisabled = true

      return isDisabled

    },
    async showModalCliente(id) {
      const response = await this.fetchContractPaymentTypes(id)
      this.tableData = response
      this.$root.$emit("bv::show::modal", "modal-contracts" + id)
    },
    async getDataForSelectedContract(idContract) {
      const { benefitMembership, benefitExtra, benefitNews } = await this.fetchContractBenefits(idContract)
      benefitMembership.sort((a, b) => {
        return new Date(a.dateIn) - new Date(b.dateIn)
      })
      benefitMembership.forEach((benefit) => {
        benefit.idUser = this.user.idUser
      })
      benefitNews.forEach((benefit) => {
        benefit.isSelected = false
        benefit.uuid = stringAleatorio()
        benefit.idUser = this.user.idUser
      })

      benefitExtra.forEach((benefit) => {
        benefit.isReseted = false
        benefit.idUser = this.user.idUser
      })

      this.setBenefitsInSelectedContract(benefitMembership)
      this.setNewBenefitsInSelectedContract(benefitNews)
      this.setExtraBenefitsInSelectedContract(benefitExtra)
    },
    setPartialDataNewBenefit(newBenefit) {
      this.isGettingBenefits = true
      const { dateIn, dateOut, qty, uuid, notes } = newBenefit
      const payload = { qty, isSelected: false, uuid, dateIn, dateOut, notes }
      this.completeDataNewBenefitInSelectedContract(payload)
      this.isGettingBenefits = false
    },
    completeDataNewBenefit(newBenefit) {
      this.isGettingBenefits = true
      const { dateIn, dateOut, qty, uuid, notes } = newBenefit
      //validar si por lo menos qty o amount tiene valor
      const isEmptyAmounts = [qty].includes("")
      if (isEmptyAmounts) {
        newBenefit.isSelected = false
        this.isGettingBenefits = false
        return showAlertMessage("Falta cantidad", "BellIcon", "¡Tiene que indicar una cantidad!", "danger", 3000, "bottom-right")
      }
      const isEmptyDates = [dateIn].includes("") && [dateOut].includes("")
      if (isEmptyDates) {
        newBenefit.isSelected = false
        this.isGettingBenefits = false
        return showAlertMessage("Falta fechas", "BellIcon", "¡Tiene que indicar las fechas", "danger", 3000, "bottom-right")
      }
      const isValidDates = moment(dateOut).isSameOrAfter(dateIn, "day")
      if (!isValidDates) {
        newBenefit.isSelected = false
        this.isGettingBenefits = false
        return showAlertMessage( "Fechas inválidas", "BellIcon", "¡Fecha fin no debe ser menor a fecha de inicio", "danger", 3000, "bottom-right")
      }
      const payload = {
        qty: qty !== "" ? parseFloat(qty) : 0,
        isSelected: true,
        uuid,
        notes: notes && notes != "" ? notes : null,
        dateIn,
        dateOut,
      }
      // muto
      this.completeDataNewBenefitInSelectedContract(payload)
      this.isGettingBenefits = false
    },
    clearNewBenefit(benefit) {
      const { uuid } = benefit
      this.resetDataNewBenefitInSelectedContract({ uuid })
    },
    qtyExtrabenefit(benefitExtra) {
      this.isGettingBenefits = true
      const { id, qty } = benefitExtra
      const payload = {
        id,
        qty: qty !== "" || qty > 0 ? parseFloat(qty) : 0,
        amount: qty !== "" || qty > 0 ? 0 : 0,
      }
      this.setDataExtraBenefitInSelectedContract(payload)
      this.isGettingBenefits = false
    },

    resetBenefitExtra(benefitExtra) {
      this.isGettingBenefits = true
      const { id } = benefitExtra
      this.resetExtraBenefitInSelectedContract({ id })
      this.isGettingBenefits = false
    },
    async deleteBenefitExtra(benefitExtra) {
      this.isGettingBenefits = true
      const { id, notes } = benefitExtra
      const payload = {
        id: id,
        idContract: this.selectedContract.id,
        notes: notes || "",
        idUser: this.user.idUser,
      }
      const swalert = {
        title: "Eliminando beneficio: " + benefitExtra.benefitname,
        message: "¿Desea eliminar este beneficio?",
        icon: "question",
        confirmButton: "Eliminar",
        cancelButton: "Cancelar",
      }

      const {isConfirmed} = await sweetAlert(swalert)

      if (isConfirmed) {
        const response = await this.deleteBenefitInContract(payload)
        if (response.status) {
          await this.getDataForSelectedContract(this.selectedContract.id)
          showAlertMessage( "Ok: proceso finalizado", "InfoIcon", response.message, "success", 4000, "bottom-right")
          this.isGettingBenefits = false
        } else {
          showAlertMessage( "No se pudo completar la operación", "InfoIcon", response.message, "warning", 4000, "bottom-right")
          this.isGettingBenefits = false
        }
      } else {
        showAlertMessage( `Operación cancelada`, "InfoIcon", `Se ha cancelado la operación`, "warning", 4000, "bottom-right")
        this.isGettingBenefits = false
      }
    },
    async updateContractOwner() {
      const idContract = this.selectedContract.id
      let valid

      const actives = await this.contractActiveServices({house: this.selectedContract.housingid, idContract})

      if (this.statusSelected == 3 && actives?.length > 0) valid = await this.autorizeCancelBookings(actives)
      else valid = await this.confirmUpdating()

      const membershipBenefits = this.selectedContract.benefitsMembership
      const extraBenefits = this.selectedContract.benefitsExtra.filter( (extraBenefit) => !extraBenefit.isReseted )
      const newBenefits = this.selectedContract.benefitsNew.filter( (newBenefit) => newBenefit.isSelected )

      extraBenefits.forEach( xtra => {
        xtra.isNew = false
      })

      newBenefits.forEach( xtra => {
        xtra.isNew = xtra.isNew || false
      })


      const payload = {
        idContract,
        numbercontract: this.selectedContract.numberContract,
        datein: this.selectedContract.dateStart,
        dateout: this.selectedContract.dateEnd,
        endDate: this.selectedContract.endDate,
        duration: parseInt(this.selectedContract.duration),
        status: this.statusSelected,
        idHousing: this.selectedContract.housingid,
        idMembership: this.selectedContract.membershipid,
        idOwner: this.selectedContract.owner,
        idPayment: this.selectedContract.paymentType,
        idReturn: this.selectedContract.returnType,
        currencyId: this.selectedContract.currencyId,
        note: this.selectedContract.notes,
        cancellationNotes: this.selectedContract.cancellationNotes,
        idUser: this.user.idUser,
        benefitXtra: [ ...extraBenefits, ...newBenefits],
        benefitContract:[...membershipBenefits,],
        isTemporal: this.selectedContract.isTemporal,
        isResale: this.selectedContract.isResale,
        priceResale: this.selectedContract.isResale ? this.selectedContract.priceResale : 0,
      }
     
      if (valid) {
        this.isUpdatingContract = true
        const respuesta = await this.updateContract(payload)
        const { status, message } = respuesta
        const newContract = makeParamsFilterContracts({ typeFilter: "idContract", idContract, }) // para pedir info de nuevp contrato

        if (status) {
          showAlertMessage("Contract Saved","BellIcon","Contract has been updated!","success",4000,"bottom-right")
          this.setContracts([])
          this.setSelectedContract(null)
          const contracts = await this.fetchContracts(newContract) //obtener el nuevo contract
          const nuevoContrato = contracts.length > 0 ? contracts[0] : null

          if (nuevoContrato) {
            this.setSelectedContract(nuevoContrato)
            this.isGettingBenefits = true
            await this.getDataForSelectedContract(idContract)
            this.isGettingBenefits = false
          }

        } else if (!status) {
          if (message) showAlertMessage( "Contract not Saved", "BellIcon", message, "danger", 4000, "bottom-right")
          else showAlertMessage( "Contract not Saved", "BellIcon", "We have an issue updating this contract, try it later", "danger", 4000, "bottom-right" )
          this.isUpdatingContract = false
        }
        this.isUpdatingContract = false

        if (!this.contracts.length && this.requestContracts) {
          this.setIsLoadingContracts(true)
          this.setContracts([])//reset arreglos
          const contracts = await this.fetchContracts(this.requestContracts)
          this.setContracts(contracts)
          this.setIsLoadingContracts(false)
        }
      } else showAlertMessage( "Contract not Saved", "BellIcon","the update was aborted", "warning", 4000, "bottom-right")
    },
    async confirmUpdating() {
      const { isConfirmed } = await this.$swal.fire({
        title: "¿Está seguro?",
        text: "Seguro de realizar la actualización",
        showDenyButton: true,
        confirmButtonText: "Sí, guardar",
        denyButtonText: "Cancelar",
      })
      return isConfirmed
    },
    goToHome() {
      this.setSelectedContract(null)
      this.$router.push({ name: "contracts" })
    },
    async showModalOwners(data) {
      const response = await this.fetchInfoOwnerOrHousing({ idContract: data.id })
      this.owners = response
      this.$root.$emit("bv::show::modal", "modal-owners" + data.id)
    },
    async autorizeCancelBookings(actives){
      const component = this.createModalComponent(actives)

      const modal = await this.$bvModal.msgBoxConfirm([component],{
        title: 'Cancelando contrato: reservas activas',
        size: 'lg'
      })

      return modal
    },
    createModalComponent(data){
      const h = this.$createElement

      const datafields = [
        {key: 'reservanumber', label: 'Folio reserva'},
        {key: 'housingname', label: 'Vivienda'},
        {key: 'datein', label: 'Llegada'},
        {key: 'dateout', label: 'Salida'},
        {key: 'statusname', label: 'Estatus'}
      ]

      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['text-center mb-1'] }, [
          'Las siguientes reservas se cancelarán: ',
        ]),
        // h('p', { class: ['text-center'] }, [h('b-spinner')]),
        h('b-table', {
          props: {
            items: data,
            fields: datafields,
            small: true,
            responsive: true,
            // show-empty: true,
            // empty-text: "No matching records found"
          }
        }),
        h('strong', { class: ['text-center pt-1'] }, [
          '¿Está seguro de cancelar este contrato?',
        ]),
      ])
      return messageVNode
    },
    statusId(state){
      // cancelado ->  3
      const statusValid = ["CANCELADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      if(state==3) this.isCancel=true
      else {
        this.isCancel=false
        if (!statusValid.includes(this.selectedContract.statuscontractname)) this.selectedContract.endDate = null
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.input-disabled:disabled {
  font-weight: bold !important;
  color: black !important;
}

.border-benfits-membership {
  border: 2px solid #333366;
}

.border-black-membership {
  border: 2px solid #049ccc;
}


.border-new-benfits-membership {
  border: 2px solid #28c76f;
}

.border-extra-benfits-membership {
  border: 2px solid #ff9f43;
}
.Cancelado {
  background-color: rgba(241, 144, 144, 0.747);
}
.Reseted {
  background-color: #e0d974;
}
.tbodyDiv {
  max-height: 30rem;
  overflow: auto;
}
</style>