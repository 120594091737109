<template>
  <b-table
    :items="blackouts"
    head-variant="light"
    :fields="fields"
    class="mb-0"
    :tbody-tr-class="rowClass"
    show-empty
    :busy.sync="isLoadingBlackouts"
    empty-text="No se encontraron blackouts"
  >
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Cargando Blackouts</strong>
      </div>
    </template>
    <template #cell(code)="data">
      <span class="text-nowrap">
        {{ data.value ? data.value : "N/A" }}
      </span>
    </template>
    <template #cell(dates)="row">
      <b-dropdown
          variant="link"
          toggle-class="p-0 mr-1"
          no-caret
          text="Form"
          v-b-tooltip.hover.v-info
          title="Ver fechas"
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="CalendarIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <div v-if="row.item.datesBlackout.length > 0">
            <b class="ml-1 mt-1">Fechas del Blackout</b>
            <b-dropdown-item  v-for="(date, index) in row.item.datesBlackout" :key="index" >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-50"><b>{{date.dateStart }}  a {{ date.dateEnd }}</b></span>
            </b-dropdown-item>
          </div>
          <div v-else>
            <p class="m-1 text-danger">Sín fechas designadas para este blackout</p>
          </div>

        </b-dropdown>
    </template>

    <template #cell(status)="row">
      <span class="text-nowrap">
        <b-badge pill :variant="row.item.status ? 'success': 'danger'" >
          {{ row.item.status ? "Activo" : "Desactivado" }}
        </b-badge>
      </span>
    </template>
   <template #cell(highseason)="row">
      <span class="text-nowrap">
        <b-badge pill :variant="row.item.highseason ? 'success': 'danger'" >
          {{ row.item.highseason ? "Activo" : "Desactivado" }}
        </b-badge>
      </span>
    </template>
    <!-- actions de edicion está ligado a Status -->
    <template #cell(actions)="row">
      <b-row>
        <b-col class="pl-0 pr-0" v-if="row.item.status">
          <b-button size="sm" v-b-tooltip.hover title="Editar Blackout" @click="setBlackoutToEdit(row.item)" variant="primary">
            <feather-icon icon="Edit2Icon" size="12" />
          </b-button>
        </b-col>

        <b-col class="pl-0 pr-0">
          <b-form-checkbox
            v-model="row.item.status"
            v-b-tooltip.hover
            title="Cambiar Status"
            :value="true"
            button
            size="sm"
            button-variant="warning"
            inline
            :unchecked-value="false"
            @input="changeStatus(row.item, $event)"
            class="custom-control-success"
            v-can="'fivesclub_contracts_show_contracts_action_button_edit_blackout_statuschange'"
            >
            <feather-icon :icon="row.item.status ? 'CheckCircleIcon': 'SlashIcon'" size="12"/>
          </b-form-checkbox>
        </b-col>

        <b-col class="pl-0 pr-0" v-if="row.item.status">
          <b-button
            size="sm"
            v-b-tooltip.hover.v-danger
            title="Borrar Blackout"
            @click="deleteBlackout(row.item)"
            variant="danger"
            v-can="'fivesclub_contracts_show_contracts_action_button_edit_blackout_delete'"
            >
            <feather-icon icon="TrashIcon" size="12" />
          </b-button>
        </b-col>
      </b-row>
    </template>

  </b-table>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { formatDatesBlackout, createArrayDates } from '@/helpers/fivesClubHelper'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { showAlertMessage, toJson } from '@/helpers/helpers'

export default {
  mixins: [utils],
  data() {
    return {
      fields: [
        { key: 'dates', label: 'Fechas', class: 'text-center' },
        { key: 'name', label: 'Nombre' },
        { key: 'status', label: 'Status' },
        { key: 'highseason', label: 'Temporada alta' },
        { key: 'actions', label: 'Acciones', class: 'text-center' },
      ],
      items: [],
      isLoadingBlackouts: false
    };
  },
  computed: {
    ...mapState("fivesClubContracts", ["selectedContract",]),
    ...mapState('fivesClubCatalogs', ['blackouts']),
    ...mapState('auth', ['user']),

  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBlackouts', 'fetchBlackoutsContracts','updateBlackoutContracts', 'deleteBlackoutContracts']),
    ...mapMutations('fivesClubCatalogs', ['setSelectedBlackout', 'setBlackouts']),
    setBlackoutToEdit(data) {
      this.$emit('set-tab-index', 2)
      data.datesBlackout = formatDatesBlackout( toJson(data.datesBlackout))
      this.setSelectedBlackout(data)
    },
    async changeStatus(item, event){
      item.isLoading = true
      const payload = {
        idUser: this.user.idUser,
        id: item.id,
        name: item.name,
        // dates: createArrayDates(item.datesBlackout),
        status: Boolean(event),
        code: item.code,
        highseason: item.highseason,
      }
      const { status, message } = await this.updateBlackoutContracts(payload)
      item.isLoading = false
      if (status) showAlertMessage('Ok: Status actualizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
    },
    async deleteBlackout(data){
      const response = await this.deleteBlackoutContracts(data.id)
      if (response) {
        this.isLoadingBlackouts = true
        const idContract = this.selectedContract.id
        const blackouts  = await this.fetchBlackoutsContracts(idContract)
        this.setBlackouts(blackouts)
        this.isLoadingBlackouts = false
      }
    }
  },
};
</script>